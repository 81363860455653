import { Component, Renderer2, Output, OnInit, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { combineLatest } from 'rxjs';

import { ModalComponent } from '../modal/modal.component';

import { Message } from '../models/message';
import { User } from '../models/user';
import { Recipient } from '../models/recipient';

import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { DataService }  from '../services/data.service';
import { MessageService }  from '../services/message.service';
import { ModalService } from '../services/modal.service';
import { RecipientService }  from '../services/recipient.service';
import { UserService }  from '../services/user.service';
import { ChunkService }  from '../services/chunk.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    @Output() saveMessage = new EventEmitter();
    @Output() sendMessage = new EventEmitter();
    @Output() toggleNotificationPanel = new EventEmitter();
    @Output() toggleMessagesPanel = new EventEmitter();
    @ViewChild('notificationsBtn', { static: false }) notificationBtn: ElementRef;
    @ViewChild('messagesBtn', { static: false }) messagesBtn: ElementRef;

    messageId = null;
    toggleNotificationBtn = false;
    toggleMessagesBtn = false;
    userData = null;
    selectedRecipient: Recipient;
    recipients: Recipient[];
    recipientEmail: string;
    isRecipientDetailOpen: boolean;
    genders: any[];
    styles: any[];
    countries: any[];
    languages: any[];

    constructor(
        private auth: AuthService,
        private router: Router,
        private renderer: Renderer2,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private dataService: DataService,
        private modalService: ModalService,
        private messageService: MessageService,
        private userService: UserService,
        private chunkService: ChunkService,
        private recipientService: RecipientService,
        private translate: TranslateService
    ) {

        translate.addLangs(['en', 'it', 'ru']);
        translate.setDefaultLang('en');
        translate.use('it');

    }

    ngOnInit() {
        this.userData = this.userService.getLocalUser();

        const urlParams = combineLatest(
            this.activatedRoute.params,
            this.activatedRoute.queryParams,
            (params, queryParams) => ({ ...params, ...queryParams})
        );

        urlParams.subscribe(routeParams => {
            if (routeParams.id !== undefined && routeParams.id !== null) {
                this.renderer.addClass(document.body, 'editing');
                this.messageId = routeParams.id;
            }
        });

        this.isRecipientDetailOpen = false;
        this.getRecipients();
        this.selectedRecipient = new Recipient();

        this.dataService.getGenericData().subscribe(
            results => {
                this.genders = results[0]['genders'];
                this.styles = results[1]['styles'];
                this.languages = results[2]['languages'];
                this.countries = results[3]['countries'];
            });
    }

    isLogged() {
        return this.auth.loggedIn;
    }

    logout() {
        this.auth.logout();
        this.router.navigate(['login']);
    }

    deleteMessage(id: number): void {
        this.messageService.deleteMessage(id)
            .subscribe(response => {
                    this.modalService.close('modal-delete');
                    this.alertService.success('alerts.message-deleted.success');
                    // PB: Timeout like send message
                    // this.router.navigate(['']);
                },
                error => {
                    this.modalService.close('modal-delete');
                    this.alertService.error('alerts.message-deleted.error');
                }
            );
    }
    
    clickSaveMessage() {
        this.saveMessage.emit(null);
    }

    clickSendMessage() {
        this.sendMessage.emit(null);
    }

    clickTogglePanel(id) {
        console.log(this.toggleNotificationBtn);
        switch (id) {
            case 'notifications':
                if (this.toggleNotificationBtn) {
                    this.toggleNotificationBtn = false;
                    this.notificationBtn.nativeElement.classList.remove('active');
                }
                else {
                    this.toggleNotificationBtn = true;
                    this.notificationBtn.nativeElement.classList.add('active');
                }

                this.toggleNotificationPanel.emit(null);
            break;
            case 'messages':
                if (this.toggleMessagesBtn) {
                    this.toggleMessagesBtn = false;
                    this.messagesBtn.nativeElement.classList.remove('active');
                    this.renderer.removeClass(document.body, 'messages');
                }
                else {
                    this.toggleMessagesBtn = true;
                    this.messagesBtn.nativeElement.classList.add('active');
                    this.renderer.addClass(document.body, 'messages');
                }

                this.toggleMessagesPanel.emit(null);
            break;
        }
        
    }

    removeHover(event) {
        /*
        var target = event.target || event.srcElement || event.currentTarget;
        var idAttr = target.attributes.id;
        var value = idAttr.nodeValue;
        target.nativeElement.blur();
        */
    }

    openUserProfile() {
        this.userService.getUser()
        .subscribe(
            result => {
                this.userData = result;
                this.modalService.open('modal-user');
            },
            error => {
                this.alertService.error('alerts.user-get.error');
            });
        
    }

    openRecipientsList() {
        this.modalService.open('modal-recipients'); 
    }

    userEdited(user: User) {
        //console.log(user);
        //console.log(this.userData);
    }

    saveUser() {
        // @PB: Fix userData and put User object instead
        this.userService.saveUser(this.userData).subscribe(
            message => {
                this.userService.setUser(this.userData);
                this.userData = this.userService.getLocalUser();

                this.alertService.success('alerts.user-saved.success');
                this.modalService.close('modal-user');
            },
            error => {
                this.alertService.error('alerts.user-saved.error');
                this.modalService.close('modal-user');
            }
        );
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    getRecipients(): void {
        this.recipientService.getRecipients()
          .subscribe(
              recipients => this.recipients = recipients,
              error =>  this.alertService.error('alerts.recipients-get.error')
          );
      }
  
      recipientForm(id): void {
          
          this.isRecipientDetailOpen = true;
  
          if (id == null) {
              this.selectedRecipient = new Recipient();
              return;
          }
  
  
          this.recipientService.getRecipient(id)
          .subscribe(
              recipient => {
                  this.selectedRecipient = recipient;
              },
              error => {
                  this.alertService.error('alerts.recipients-saved.error');
              }
          );
      }
  
      newRecipient(): void{
          console.log(this.selectedRecipient);
          if (this.selectedRecipient.id == undefined) {
                         
              this.recipientService.newRecipient(this.selectedRecipient)
              .subscribe(
                  recipient => {
                      this.alertService.success('alerts.recipient-saved.success');
                      this.backToList();
                      // @PB: Remove if using split view ;)
                      this.selectedRecipient = new Recipient();
                      this.getRecipients();
                  },
                  error => {
                      this.alertService.error('alerts.recipient-saved.error');
                  }
              );
          } else {
  
              this.recipientService.saveRecipient(this.selectedRecipient)
              .subscribe(
                  success => {
                      this.alertService.success('alerts.recipient-saved.success');
                      this.getRecipients();
                  },
                  error => this.alertService.error('alerts.recipient-saved.error')
              );
          }
  
      }
  
      deleteRecipient(id): void {
          this.recipientService.deleteRecipient(id)
          .subscribe(
              success => {
                  this.alertService.success('alerts.recipient-deleted.success');
                  this.selectedRecipient = new Recipient();
                  this.getRecipients();
              },
              error => {
                  this.alertService.error('alerts.recipient-deleted.error')
              }
          );
      }
  
      backToList(): void {
          console.log('Drill up');
          this.isRecipientDetailOpen = false;
      }

      closeRecipientModal(id: string) {
        this.isRecipientDetailOpen = false;
        this.modalService.close(id);
    }
}
