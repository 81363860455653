export enum NotificationCategory {
    'Sistema' = 0,
    'Link interno' = 1,
    'Link esterno' = 2,
}

export class Notification {
    id: number;
    category: NotificationCategory;
    created: string;
    text: string;
    url: string;
    message_id: number;
    message_chunk_id: number;
    read: boolean;
    title: string;
}
