import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { map } from "rxjs/operators";

import { Chunk } from "../models/chunk";

import { ChunkSerializer } from "../serializers/chunk-serializer";
import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ChunkService {
    
    API_ENDPOINT = 'chunks';
    API_CHUNKSEARCH_ENDPOINT = 'chunksearch';
    API_CHUNKTRANSLATE_ENDPOINT = 'chunktranslation';
    API_MESSAGECHUNKS_ENDPOINT = 'messagechunks';
    API_MESSAGES_ENDPOINT = 'messages';

    private serializer = new ChunkSerializer();
    private selectedChunk: Subject<Chunk> = new Subject<Chunk>();

    constructor(private http: HttpClient) { }

    getChunks(id: number): Observable<Chunk[]> {
        return this.http.get<Chunk[]>(`${AppConstants.API_URL}/${this.API_MESSAGES_ENDPOINT}/${id}/${this.API_ENDPOINT}/`);
    }

    newChunk(chunk: Chunk): Observable<Chunk> {
        return this.http.post<Chunk>(
                `${AppConstants.API_URL}/${this.API_ENDPOINT}/`,
                this.serializer.toJson(chunk)
            )
            .pipe(map(data => this.serializer.fromJson(data)));
    }

    //Da fare refactoring...
    createChunk(): Chunk {
        return this.serializer.fromJson(new Chunk());
    }

    getChunk(id: number): Observable<Chunk> {
        return this.http.get<Chunk>(`${AppConstants.API_URL}/messages/${id}/${this.API_ENDPOINT}/`)
            .pipe(map(data => this.serializer.fromJson(data)));
    }

    getRawChunk(id: number): any {
        return this.http.get<Chunk>(`${AppConstants.API_URL}/messages/${id}/${this.API_ENDPOINT}/`);
    }

    saveChunk(data: any): Observable<Chunk> {
        return this.http.post<Chunk>(`${AppConstants.API_URL}/${this.API_MESSAGECHUNKS_ENDPOINT}/`, data);
    }

    deleteChunk(id: number): Observable<Chunk> {
        return this.http.delete<Chunk>(`${AppConstants.API_URL}/${this.API_MESSAGECHUNKS_ENDPOINT}/${id}/`);
    }

    chunkSearch(data: any): Observable<Chunk[]> {
        return this.http.post<Chunk[]>(`${AppConstants.API_URL}/${this.API_CHUNKSEARCH_ENDPOINT}/`, data);
    }

    chunkTranslate(data: any): Observable<Chunk[]> {
        return this.http.post<Chunk[]>(`${AppConstants.API_URL}/${this.API_CHUNKTRANSLATE_ENDPOINT}/`, data);
    }
}
