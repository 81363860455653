import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {
    
    API_STYLES_ENDPOINT = 'styles';
    API_COUNTRIES_ENDPOINT = 'countries';
    API_LANG_ENDPOINT = 'languages';
    API_GENDER_ENDPOINT = 'genders';
    API_GENERICDATA_ENDPOINT = 'generic-data';

    constructor(private http: HttpClient) { }


    getGenericData() {
        return this.http.get<any[]>(`${AppConstants.API_URL}/${this.API_GENERICDATA_ENDPOINT}/`);
    }
    
    getStyles() {
        return this.http.get<any[]>(`${AppConstants.API_URL}/${this.API_STYLES_ENDPOINT}/`);
    }

    getCountries() {
        return this.http.get<any[]>(`${AppConstants.API_URL}/${this.API_COUNTRIES_ENDPOINT}/`);
    }

    getLanguages() {
        return this.http.get<any[]>(`${AppConstants.API_URL}/${this.API_LANG_ENDPOINT}/`);
    }

    getGenders() {
        return this.http.get<any[]>(`${AppConstants.API_URL}/${this.API_GENDER_ENDPOINT}/`);
    }

}
