import { Component, Renderer2, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {FormControl, FormGroup, ReactiveFormsModule, FormsModule} from '@angular/forms';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

import { combineLatest ,  forkJoin } from 'rxjs';
//import { debounceTime } from 'rxjs/operators';

import { Chunk } from '../models/chunk';
import { Message } from '../models/message';
import { Recipient } from '../models/recipient';
import { User } from '../models/user';

import { AlertService }  from '../services/alert.service';
import { ChunkService }  from '../services/chunk.service';
import { MessageService }  from '../services/message.service';
import { RecipientService }  from '../services/recipient.service';
import { UserService }  from '../services/user.service';
import { ModalService }  from '../services/modal.service';
import { DataService }  from '../services/data.service';
import { MessageChunk } from '../models/message-chunk';
import { AppConstants } from '../constants';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
})
export class MessageDetailComponent implements OnInit {

    @Output() chunkResults = new EventEmitter();
    @Output() chunkSearchData = new EventEmitter();

    chunkPosition: Array<any> = [
        { "key": "0-SJ", "value": "subject" },
        { "key": "1-GR", "value": "greetings"},
        { "key": "2-OL", "value": "opening-lines"},
        { "key": "3-BO", "value": "body"},
        { "key": "4-CL", "value": "closing-lines"},
        { "key": "5-CC", "value": "complimentary-close"}
    ];
    message: Message;
    recipient: Recipient;
    recipients: Recipient[];
    user: User;
    chunks: Chunk[];
    styles = [];
    countries = [];
    languages = [];
    genders = [];
    selectedFromCountry = null;
    selectedToCountry = null;
    selectedStyle = null;
    selectedFromGender = null;
    selectedToGender = null;
    selectedFromLang = null;
    selectedToLang = null;
    previewMode = false;
    chunkId: number;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private messageService: MessageService,
        private recipientService: RecipientService,
        private chunkService: ChunkService,
        private userService: UserService,
        private alertService: AlertService,
        private renderer: Renderer2,
        private modalService: ModalService,
        private translate: TranslateService
    ) {

        translate.addLangs(['en', 'it', 'ru'])
        translate.setDefaultLang('en');
        translate.use('it');

    }

    ngOnInit() {
        this.dataService.getGenericData().subscribe(
        results => {
            this.genders = results[0]['genders'];
            this.selectedToGender = "N";
            this.styles = results[1]['styles'];
            this.selectedStyle = "N";
            this.languages = results[2]['languages'];
            this.countries = results[3]['countries'];
        });
        
        this.userService.getUser().subscribe(result => {
            this.user = result;
            this.selectedFromLang = this.user.user_language;
            this.selectedFromCountry = this.user.country;
            this.selectedFromGender = this.user.gender;
        });

        this.recipientService.getRecipients().subscribe(recipients => {
            this.recipients = recipients
        });

        if (this.activatedRoute.snapshot.data.new_message) {
            this.renderer.removeClass(document.body, 'messages');
            this.renderer.removeClass(document.body, 'chunks');
            this.renderer.addClass(document.body, 'editing');

            this.message = new Message();
            this.recipient = new Recipient();
            this.chunks = [];
        }
        else {
            const urlParams = combineLatest(
              this.activatedRoute.params,
              this.activatedRoute.queryParams,
              (params, queryParams) => ({ ...params, ...queryParams})
            );

            urlParams.subscribe(routeParams => {
                this.renderer.removeClass(document.body, 'messages');
                this.renderer.removeClass(document.body, 'chunks');

                //@HV: se routeParams contiene l'ID allora cerco il messaggio
                if (routeParams.id !== undefined && routeParams.id !== null) {
                    this.getMessage(routeParams.id);                    
                    this.renderer.addClass(document.body, 'editing');
                }
                else {

                    this.renderer.removeClass(document.body, 'editing');
                }
            });
        }


    }

    getMessage(id: number) {
        this.messageService.getMessage(id)
            .subscribe(message => {

                this.message = message;
                let recipient_d = this.recipientService.getRawRecipient(this.message.recipient_id);
                let chunks_d = this.chunkService.getChunks(this.message.id);

                if (this.message.recipient_id == null) {
                    this.chunkService.getChunks(this.message.id).subscribe(
                        results => {
                            this.recipient = new Recipient;
                            this.chunks = <Chunk[]>results['messagechunks'];
                        }
                    );
                } else {
                    forkJoin([recipient_d, chunks_d]).subscribe(
                        results => {
                            this.recipient = <Recipient>results[0];
                            this.chunks = <Chunk[]>results[1]['messagechunks'];
                        },
                        error => {
                            // @PB: Error details
                            this.alertService.error('alerts.message-get.error');
                        }
                    );
                }
               
                this.selectedFromGender = this.message.from_gender;
                this.selectedToGender = this.message.to_gender;
                this.selectedToCountry = this.message.to_country;
                this.selectedStyle =  this.message.style;
                this.selectedFromLang = this.message.from_lang;
                this.selectedToLang = this.message.to_lang;

                this.messageService.setCurrentMessage(this.message);

                if (this.message.status == "S")
                    this.previewMode = true;
                else
                    this.previewMode = false;
            },
            error => {
                this.alertService.error('alerts.message-get.error');
            }
        );

    }

    chunkSearch(input: string, position: string) {

        var data: {[k: string]: any} = {};

        data.string = input;
        data.position = position;
        data.from_lang = this.message.from_lang;
        data.from_country = this.message.from_country;
        data.to_lang = this.message.to_lang;
        data.to_country = this.message.to_country;
        data.from_gender = this.user.gender;
        data.to_gender = this.message.to_gender;
        data.style = this.selectedStyle;
        
        // @PB: May not be null, send only if a int number is available (chunk order)
        // data.prev_chunk_id = 1;

        this.chunkSearchData.emit(data);

        this.chunkService.chunkSearch(data)
        .subscribe(
        results => {
            this.renderer.addClass(document.body, 'chunks');
            this.chunkResults.emit(results);
        },
        error => {
            this.alertService.error('alerts.chunk-search.error');
        });
    }

    deleteChunkClicked(id: number) {
        this.chunkId = id;
    }

    deleteChunk() {
        
        this.chunkService.deleteChunk(this.chunkId)
        .subscribe(
            response => {
                
                this.modalService.close('modal-chunk-delete');
                this.alertService.success('alerts.chunk-deleted.success');
                this.chunkId =  null;

                // @PB: Kinda hack to refresh the view
                this.ngOnInit();
            },
            error => {
                this.modalService.close('modal-chunk-delete');
                this.alertService.error('alerts.chunk-deleted.error');
                this.chunkId =  null;
            }
        );
    }

    closeModal() {
        this.modalService.close('modal-chunk-delete');
        this.chunkId =  null;
    }

    previewToggle() {
        this.previewMode = !this.previewMode;
    }

    changeRecipient () {        

        this.recipientService.getRawRecipient(this.message.recipient_id)
        .subscribe(
            recipient => {

                this.recipient = recipient;
                this.selectedStyle = this.selectedStyle != undefined && this.selectedStyle != "N" ? this.selectedStyle : this.recipient.style;
                this.selectedToCountry = this.selectedToCountry != undefined ? this.selectedToCountry : this.recipient.country;
                this.selectedToLang = this.selectedToLang != undefined ? this.selectedToLang : this.recipient.language;
                this.selectedToGender = this.selectedToGender != undefined && this.selectedToGender != "N" ? this.selectedToGender : this.recipient.gender;
            },
            error => {
                this.alertService.error('alerts.recipient-get.error');
            }
        );

    }
}
