import { Notification } from '../models/notification';

export class NotificationSerializer {
    fromJson(json: any): Notification {
        const notification = new Notification();

        notification.id = json.id;
        notification.category = json.category;
        notification.created = json.created;
        notification.text = json.text;
        notification.url = json.url;
        notification.message_id = json.message_id;
        notification.message_chunk_id = json.message_chunk_id;
        notification.read = json.read;
        notification.title = json.title;

        return notification;
    }

    toJson(notification: Notification): any {
        return {
            id: notification.id,
            category: notification.category,
            created: notification.created,
            text: notification.text,
            url: notification.url,
            message_id: notification.message_id,
            message_chunk_id: notification.message_chunk_id,
            read: notification.read,
            title: notification.title
        };
    }
}
