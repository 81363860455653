import { Component, OnInit, Input, Output, ViewChild, Renderer2, OnChanges, SimpleChanges, EventEmitter, ElementRef, TemplateRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { AlertService } from '../services/alert.service';
import { ModalService } from '../services/modal.service';
import { ChunkService } from '../services/chunk.service';
import { Chunk } from '../models/chunk';


import { fromEvent } from 'rxjs';
import {
    debounceTime,
    map,
    distinctUntilChanged,
    filter
} from 'rxjs/operators';

@Component({
  selector: 'app-messagechunks',
  templateUrl: './messagechunks.component.html'
})
export class MessagechunksComponent implements OnInit, OnChanges {

    @Input() position: string;
    @Input() chunks: Chunk[];

    @Output() chunkSearch = new EventEmitter();
    @Output() deleteChunkEvent = new EventEmitter();

    current_chunk: Chunk[];
    from_text: string;
    textarea_isactive: boolean = false;

    constructor(
        private renderer: Renderer2,
        private alertService: AlertService,
        private modalService: ModalService,
        private chunkService: ChunkService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() { }

    ngAfterViewInit() { }

    ngOnChanges(changes: SimpleChanges) {
        this.loadChunk();
    }

    onChange(value: string) {
        if (value.trim().length > 0) {
            this.chunkSearch.emit(value.trim());
            this.renderer.removeClass(document.body, 'chunks');
        }
        else {
            this.renderer.removeClass(document.body, 'chunks');
        }
    }

    onFocus() {
        this.textarea_isactive = true;
    }

    onBlur() {
        this.textarea_isactive = false;
    }

    chunksMobileClick() {
        document.getElementById('message').classList.add('close');
        document.getElementById('chunks').classList.add('open');
    }

    deleteChunkClicked(id: number): void {
        this.deleteChunkEvent.emit(id);
        this.modalService.open('modal-chunk-delete');
    }

    loadChunk () {
        
        this.current_chunk = this.chunks.filter(
            (el: Chunk) => el.position == this.position
        );
        
        this.current_chunk.push(new Chunk());
    }
}
