import { Component, Renderer2, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';


import { AlertService } from '../services/alert.service';
import { MessageService } from '../services/message.service';
import { RecipientService } from '../services/recipient.service';

import { Message } from '../models/message';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html'
})

export class MessageListComponent {
    @Input() messages: Message[];
    @Input() folders: any[];
    @Output() messageClicked = new EventEmitter<Message>();
    @Output() newMessage = new EventEmitter<Message>();
//    @Output() folderSelected = new EventEmitter();
//    @ViewChild('foldersDiv', { static: true }) foldersDiv: ElementRef;
/*
    folders = [
        //{id: 'I', text: 'Inbox',    messages: null},
        {id: 'D', text: 'Drafts',   messages: null},
        {id: 'S', text: 'Sent',     messages: null},
        //{id: 'X', text: 'Deleted',  messages: null}
    ];
*/
    recipients = [];

    constructor(
        private renderer: Renderer2,
        private alertService: AlertService,
        private messageService: MessageService,
        private recipientService: RecipientService
    ) {}

    ngOnInit() {
        //this.getMessages();
    }
    
    onClick(message: Message) {
        this.messageClicked.emit(message);
    }

    onClickNewMessage() {
        this.newMessage.emit(null);
    }    

}
