import { Message } from '../models/message';

export class MessageSerializer {
    fromJson(json: any): Message {
        const message = new Message();

        message.id = json.id;
        message.url = json.url;
        message.from_lang = json.from_lang;
        message.to_lang = json.to_lang;
        message.from_country = json.from_country;
        message.to_country = json.to_country;
        message.from_gender = json.from_gender;
        message.to_gender = json.to_gender;
        
        message.recipient_id = json.recipient_id;
        message.recipient_data = json.recipient;
        message.status = json.status;
        message.style = json.style;
        message.title = json.title;
        message.abstract = json.abstract;
        message.chunks = json.chunks;
        message.created = json.created;
        message.updated = json.updated;
        message.sent_at = json.sent_at;

        return message;
    }

    toJson(message: Message): any {
        return {
            id: message.id,
            url: message.url,
            from_lang: message.from_lang,
            to_lang: message.to_lang,
            from_country: message.from_country,
            to_country: message.to_country,
            from_gender: message.from_gender,
            to_gender: message.to_gender,
            recipient_id: message.recipient_id,
            recipient: message.recipient_data,
            status: message.status,
            style: message.style,
            title: message.title,
            abstract: message.abstract,
            chunks: message.chunks,
            created: message.created
        };
    }
}
