import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { map } from "rxjs/operators";

import { User, JobPosition, ProductMarket } from '../models/user';

import { UserSerializer } from '../serializers/user-serializer';
import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    API_ENDPOINT = 'user-profile';
    
    private userSerializer = new UserSerializer();

    constructor(private http: HttpClient) { }

    setUser(data) {
        localStorage.setItem('user', JSON.stringify(data));
    }

    saveUser(user: User): Observable<User> {
        return this.http.put<User>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/`, this.userSerializer.toJson(user))
            .pipe(map(data => this.userSerializer.fromJson(data)));
    }

    getLocalUser() {
        return this.userSerializer.fromJson(JSON.parse(localStorage.getItem('user')));
    }

    getUser(): Observable<User> {
        return this.http.get<User>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/`);
    }

    unsetUser() {
        localStorage.removeItem('user');
    }

    checkUser() {
        return (localStorage.getItem('user') !== null);
    }
}
