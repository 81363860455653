import { Recipient } from './recipient';

export class Message {
    id: number;
    url: string;
    from_lang: string;
    to_lang: string;
    from_country: string;
    to_country: string;
    from_gender: string;
    to_gender: string;
    recipient_id: number;
    recipient_data: any;
    style: string;
    status: string;
    title: string;
    abstract: string;
    chunks: string;
    created: string;
    updated: string;
    sent_at: string;
    messagechunks: any;
}
