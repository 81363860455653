import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { AppConstants } from '../constants';


@Injectable()
export class AuthService {
    API_ENDPOINT = 'api-token-auth'

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    login(username: string, password: string): Observable<boolean> {
        return this.http.post<{token: string}>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/`, {email: username, password: password})
            .pipe(
                map(result => {
                    localStorage.setItem('access_token', result.token);
                    this.userService.setUser(result);                    
                    return true;
                })
            );
    }

    logout() {
        localStorage.removeItem('access_token');
        this.userService.unsetUser();
    }

    public get loggedIn(): boolean {
        return (localStorage.getItem('access_token') !== null && this.userService.checkUser());
    }
}
