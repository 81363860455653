import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent {
    public username: string;
    public password: string;
    public error: string;

    constructor(private auth: AuthService, private router: Router, translate: TranslateService) {
        translate.addLangs(['en', 'it', 'ru'])
        translate.setDefaultLang('en');
        translate.use('it');
    }

    public submit() {
        this.auth.login(this.username, this.password)
            .pipe(first())
            .subscribe(
                result => this.router.navigate(['']),
                err => this.error = "Wrong username or password"
            );
    }
}
