import { Chunk } from '../models/chunk';

export class ChunkSerializer {
    fromJson(json: any): Chunk {
        const chunk = new Chunk();

        chunk.id = json.id;
        chunk.chunk_status = json.chunk_status;
        chunk.from_text = json.from_text;
        chunk.message = json.message;
        chunk.order = json.order;
        chunk.position = json.position;
        chunk.to_text = json.to_text;

        return chunk;
    }

    toJson(chunk: Chunk): any {
        return {
            id: chunk.id,
            chunk_status: chunk.chunk_status,
            from_text: chunk.from_text,
            message: chunk.message,
            order: chunk.order,
            position: chunk.position,
            to_text: chunk.to_text
        };
    }
}
