import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Notification, NotificationCategory } from '../models/notification';

import { AlertService } from '../services/alert.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html'
})
export class NotificationListComponent {
    @Input() notifications: Notification[];
    @Output() notificationClicked = new EventEmitter<Notification>();

    constructor(
        private router: Router,
        private alertService: AlertService,
        private notificationService: NotificationService
    ) { }

    actionNotification(notification: Notification) {
        this.notificationService.setNotificationRead(notification).subscribe(
            notification => {},
            error => {
                this.alertService.error('alerts.notification-get.error');
            });

        if (notification.category == 1) {
            this.router.navigate(['/message-detail/' + notification.message_id]);
            return true;
        }

        if (notification.category == 2) {
            if (!/^http[s]?:\/\//.test(notification.url)) {
                window.open('http://' + notification.url, '_blank');
            }
            else {
                window.open(notification.url, '_blank');
            }

            return true;
        }
    }

    getNotificationCategory(id): any {
        return NotificationCategory[id];
    }
    
}
