
export class Chunk {
    id: number;
    chunk_status: string;
    from_text: string;
    message: number;
    order: number;
    position: string;
    to_text: string;
}
