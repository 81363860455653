import { User } from '../models/user';

export class UserSerializer {
    fromJson(json: any): User {
        const user = new User();

        user.id = json.id;
        user.first_name = json.first_name;
        user.gender = json.gender;
        user.job_position = json.job_position;
        user.last_name = json.last_name;
        user.product_market = json.product_market;
        user.token = json.token;
        user.ui_language = json.ui_language;
        user.user_language = json.user_language;
        user.country = json.country;

        return user;
    }

    toJson(user: User): any {
        return {
            id: user.id,
            first_name: user.first_name,
            gender: user.gender,
            job_position: user.job_position,
            last_name: user.last_name,
            product_market: user.product_market,
            token: user.token,
            ui_language: user.ui_language,
            user_language: user.user_language,
            country: user.country
        };
    }
}
