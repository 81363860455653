import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { map } from "rxjs/operators";

import { Notification } from '../models/notification';

import { MessageService } from './message.service';

import { NotificationSerializer } from '../serializers/notification-serializer';
import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    
    API_ENDPOINT = 'notifications';

    private serializer = new NotificationSerializer();
    private selectedNotification: Subject<Notification> = new Subject<Notification>();

    constructor(private http: HttpClient) { }

    getNotifications(): Observable<Notification[]> {
        return this.http.get<Notification[]>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/`)
            .pipe(map(data => data.map((item) => this.serializer.fromJson(item))));
    }

    setNotificationRead(notification: Notification): Observable<Notification> {
        notification.read = true;

        return this.http.put<Notification>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${notification.id}/`, notification)
            .pipe(map(data => this.serializer.fromJson(data)));
    }
}
