import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { map } from "rxjs/operators";

import { Message } from '../models/message';
import { Recipient } from '../models/recipient';

import { RecipientService } from './recipient.service';

import { MessageSerializer } from '../serializers/message-serializer';
import { RecipientSerializer } from '../serializers/recipient-serializer';

import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    
    API_ENDPOINT = 'messages';
    API_STYLES_ENDPOINT = 'styles';
    API_COUNTRIES_ENDPOINT = 'countries';
    API_LANG_ENDPOINT = 'languages';
    API_GENDER_ENDPOINT = 'genders';

    private messageSerializer = new MessageSerializer();
    private recipientSerializer = new RecipientSerializer();
    private messages: Message[];
    private selectedMessage: Message;

    constructor(private http: HttpClient) { }

    getMessages(): Observable<Message[]> {
        return this.http.get<Message[]>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/`)
            .pipe(map(data => data.map((item) => this.messageSerializer.fromJson(item))));
    }

    newMessage(message: Message): Observable<Message> {
        console.log(message);
        return this.http.post<Message>(
                `${AppConstants.API_URL}/${this.API_ENDPOINT}/`,
                this.messageSerializer.toJson(message)
            )
            .pipe(map(data => this.messageSerializer.fromJson(data)));
    }

    //Da fare refactoring...
    createMessage(): Message {
        return new Message();
    }

    getMessage(id: number): Observable<Message> {
        return this.http.get<Message>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/`)
            .pipe(map(data => this.messageSerializer.fromJson(data)));
    }

    getRawMessage(id: number): any {
        return this.http.get<Message>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/`);
    }

    saveMessage(message: Message): Observable<Message> {
        return this.http.put<Message>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${message.id}/`, this.messageSerializer.toJson(message))
            .pipe(map(data => this.messageSerializer.fromJson(data)));
    }

    sendMessage(id: number): Observable<Message> {
        return this.http.get<any>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/send/`);
    }

    deleteMessage(id: number): Observable<Message> {
        return this.http.delete<Message>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/`);
    }

    setCurrentMessage(msg: Message) {
        this.selectedMessage = msg;
    }

    getCurrentMessage(): Message {
        return this.selectedMessage;
    }
}
