import { Component, OnInit, Output, Renderer2, Input, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Chunk } from '../models/chunk';

import { ChunkService }  from '../services/chunk.service';

@Component({
  selector: 'app-chunk-list',
  templateUrl: './chunk-list.component.html'
})
export class ChunkListComponent implements OnInit {
    @Input() chunks: Chunk[];

    @Output() saveChunk = new EventEmitter();
    @Output() requestChunkTranslate = new EventEmitter();

    constructor(
        private chunkService: ChunkService,
        private renderer: Renderer2,
        private router: Router
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.chunks = [];
            }
        });
    }

    ngOnInit() { }

    chunkClicked(chunk: Chunk) {
        this.saveChunk.emit(chunk);
    }

    requestTranslate() {
        this.requestChunkTranslate.emit(true);
    }

    closeChunkPanel() {
        this.renderer.removeClass(document.body, 'chunks');
        document.getElementById('message').classList.remove('close');
        document.getElementById('chunks').classList.remove('open');
    }
}
