import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent }      from './dashboard/dashboard.component';
import { LoginComponent }      from './login/login.component';
import { MessageDetailComponent }      from './message-detail/message-detail.component';
import { PageNotFoundComponent }      from './page-not-found/page-not-found.component';

import { AuthGuard } from './auth.guard';

//@HV: Routes
/*
const routes: Routes = [
  { path: '', redirectTo: '/dashboard', canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'dashboard/new-message', canActivate: [AuthGuard], component: DashboardComponent, data : {new_message : true} },
  { path: 'dashboard/message-detail/:id', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'dashboard/message-detail/:id/:chunk', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent }
];
*/

//@PB: Routes
const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'new-message', canActivate: [AuthGuard], component: DashboardComponent, data : {new_message : true} },
  { path: 'message-detail/:id', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'message-detail/:id/:chunk', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
   imports: [ RouterModule.forRoot(routes) ],
   exports: [RouterModule]
})
export class AppRoutingModule { }
