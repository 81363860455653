import { Component, Renderer2, Input, ElementRef, OnInit, ViewChild, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {combineLatest} from 'rxjs';

import { MessageDetailComponent } from '../message-detail/message-detail.component';
import { MessageListComponent } from '../message-list/message-list.component';

import { Chunk } from '../models/chunk';
import { Message } from '../models/message';
import { Notification } from '../models/notification';
import { Recipient } from '../models/recipient';
import { User } from '../models/user';

import { AlertService } from '../services/alert.service';
import { ChunkService } from '../services/chunk.service';
import { MessageService } from '../services/message.service';
import { RecipientService } from '../services/recipient.service';
import { NotificationService } from '../services/notification.service';

import { UserService } from '../services/user.service';
import { NotificationListComponent } from '../notification/notification-list.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

    @Input() notifications: Notification[];
    @Input() messages: Message[];
    @ViewChild(MessageDetailComponent, { static: true }) messageDetail;
    @ViewChild(MessageListComponent, { static: true }) messageList;
    @ViewChild(NotificationListComponent, { static: true }) notificationPanel;
    @ViewChild('messagesPanelDiv', { static: true }) messagesPanel: ElementRef;

    chunks: Chunk[] = [];
    chunkSearchData = null;
    recipients: Recipient[] = [];
    user: User;
    recipient: Recipient;
    isMsgDetail = false;
    notificationsListOpen = false;
    folderSelected = null;
    folders = [
        //{id: 'I', text: 'Inbox',    messages: null},
        {id: 'D', text: 'Drafts',   messages: null},
        {id: 'S', text: 'Sent',     messages: null},
        //{id: 'X', text: 'Deleted',  messages: null}
    ];
    public message: Message;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private renderer: Renderer2,
        private messageService: MessageService,
        private recipientService: RecipientService,
        private chunkService: ChunkService,
        private alertService: AlertService,
        private notificationService: NotificationService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.user = this.userService.getLocalUser();
        this.getNotifications();
        this.getMessages();
        this.renderer.removeClass(document.body, 'chunks');

        if (this.activatedRoute.snapshot.data.new_message) {
            this.renderer.addClass(document.body, 'editing');
        }
        else {
            const urlParams = combineLatest(
                this.activatedRoute.params,
                this.activatedRoute.queryParams,
                (params, queryParams) => ({ ...params, ...queryParams})
    	    );

    	    urlParams.subscribe(routeParams => {
    	    	if (routeParams.id !== undefined && routeParams.id !== null) {
                    this.renderer.addClass(document.body, 'editing');
                    this.isMsgDetail = true;
                }
                else {

                    this.renderer.removeClass(document.body, 'editing');
                }
    	    });
        }
    }

    

    getNotifications(): void {
        this.notificationService.getNotifications().subscribe(
            notifications => {
                this.notifications = notifications;
            },
            error => {
                this.alertService.error('alerts.notifications-get.error');
            });
    }

    getMessages(): void {
        this.messageService.getMessages()
        .subscribe(messages => {
            this.recipientService.getRecipients()
                .subscribe(
                recipients => {
                    this.messages = messages;
                    this.recipients = recipients;

                    this.messages.map(item => {
                        item.recipient_data = recipients.find(x => x.id === item.recipient_id);
                    });
                    
                    // @PB: Hardcoded like the folder structure
                    this.folders[0].messages = this.messages.filter((msg: Message) => msg.status == "D");
                    this.folders[1].messages = this.messages.filter((msg: Message) => msg.status == "S");

                },
                error => {
                    this.alertService.error('alerts.recipients-get.error');
                });
        },
        error => {
            this.alertService.error('alerts.messages-get.error');
        });
    }

    setCurrentMessage(message: Message) {
        this.messageService.setCurrentMessage(message);
        this.router.navigate(['/message-detail/' + message.id]);
    }

    setNewMessage() {
        this.messageService.setCurrentMessage(new Message());
    }

    filterMessages(id: string) {
        console.log(id);
        //this.folderSelected = id;
        //this.getMessages();
    }

    saveMessage() {
       
        this.messageService.setCurrentMessage(this.messageDetail.message);
        let msg = this.messageService.getCurrentMessage();
        
        if (this.activatedRoute.snapshot.data.new_message) {

            if (msg.recipient_id == undefined || msg.recipient_id == null) {
                // this.alertService.warn('Please select a recipient before save.');

                msg.recipient_id = null;
                msg.from_lang = this.messageDetail.selectedFromLang;
                msg.to_lang = this.messageDetail.selectedToLang;
                msg.from_country = this.messageDetail.selectedFromCountry;
                msg.to_country = this.messageDetail.selectedToCountry;
                msg.style = this.messageDetail.selectedStyle;
                msg.from_gender = this.messageDetail.selectedFromGender;
                msg.to_gender = this.messageDetail.selectedToGender;
                msg.status = "D";
                msg.title = "";
                msg.abstract = "";
                
                this.messageService.newMessage(msg)
                .subscribe(
                    message => {
                        this.alertService.success('alerts.message-saved.success');
                        // @PB: Block UI and set timeout properly ;)
                        //setTimeout(() => this.router.navigate(['/message-detail/' + message.id]) , 3000);
                        this.router.navigate(['/message-detail/' + message.id]);
                    },
                    err => {
                        //this.alertService.error(err.error.detail);
                        this.alertService.error('alerts.message-saved.error');
                        
                    }
                );
            }
            else {
            
                let recipient = this.recipientService.getRecipient(msg.recipient_id)
                .subscribe(
                    recipient => {
                     
                        this.recipient = recipient;
                        msg.from_lang = this.messageDetail.selectedFromLang;
                        msg.to_lang = this.messageDetail.selectedToLang;
                        msg.from_country = this.messageDetail.selectedFromCountry;
                        msg.to_country = this.messageDetail.selectedToCountry;
                        msg.style = this.messageDetail.selectedStyle;
                        msg.from_gender = this.messageDetail.selectedFromGender;
                        msg.to_gender = this.messageDetail.selectedToGender;
                        msg.status = "D";
                        msg.title = "";
                        msg.abstract = "";
                        
                        this.messageService.newMessage(msg)
                        .subscribe(
                            message => {
                                this.alertService.success('alerts.message-saved.success');
                                //setTimeout(() => this.router.navigate(['/message-detail/' + message.id]) , 3000);
                                this.router.navigate(['/message-detail/' + message.id]);
                            },
                            err => {
                                this.alertService.error('alerts.message-saved.error');
                            }
                        );
                    },
                    err => {
                        this.alertService.error(err.error.detail);
                    }
                );                
            }
        }
        else {
            msg.updated = new Date().toISOString();
            msg.from_lang = this.messageDetail.selectedFromLang;
            msg.to_lang = this.messageDetail.selectedToLang;
            msg.from_country = this.messageDetail.selectedFromCountry;
            msg.to_country = this.messageDetail.selectedToCountry;
            msg.from_gender = this.messageDetail.selectedFromGender;
            msg.to_gender = this.messageDetail.selectedToGender;
            msg.style = this.messageDetail.selectedStyle;
            
            this.messageService.saveMessage(msg)
                .subscribe(
                    message => {
                        this.alertService.success('alerts.message-saved.success');
                    },
                    err => {
                        this.alertService.error('alerts.message-saved.error');
                    }
                );
        }
    }

    sendMessage() {

        if (this.activatedRoute.snapshot.data.new_message) {
            this.alertService.warn('Please save message before send.');
        }
        else {
        
            let msg = this.messageService.getCurrentMessage();
            this.messageService.sendMessage(msg.id)
                .subscribe(
                    message => {
                        this.alertService.success('alerts.message-sent.success');
                        setTimeout(() => this.router.navigate(['/']) , 3000);
                    },
                    err => {
                        this.alertService.error('alerts.message-sent.error');
                    }
                );
        }
    }

    chunkList(chunks: Chunk[]) {
        this.chunks = chunks;
    }

    chunkClicked(chunk) {
        let filtered_chunks = this.messageDetail.chunks.filter((el: Chunk) => el.position == chunk.position);
        let msg = this.messageService.getCurrentMessage();

        var data: {[k: string]: any} = {};
        data.position = chunk.position;
        data.chunk = chunk.id;
        data.order = filtered_chunks.length;
        data.message = msg.id;

        this.chunkService.saveChunk(data).subscribe(
            response => {
                this.chunkService.getChunks(msg.id).subscribe(
                    message => {
                        console.log(message);
                    },
                    error => {

                    }
                );
                this.alertService.success('alerts.chunk-saved.success');
                this.messageDetail.ngOnInit();
                // PB
                this.router.navigate(['/message-detail/' + msg.id + '/' + response.id]);
            },
            error => {
                this.alertService.error('alerts.chunk-saved.error');
            }
        );
    }

    setChunkSearchData(data) {
        this.chunkSearchData = data;
    }

    requestChunkTranslate() {
        this.chunkService.chunkTranslate(this.chunkSearchData)
        .subscribe(result => {
            
            let filtered_chunks = this.messageDetail.chunks.filter((el: Chunk) => el.position == this.chunkSearchData.position);
            let msg = this.messageService.getCurrentMessage();

            var data: {[k: string]: any} = {};
            data.position = this.chunkSearchData.position;
            data.chunk = result[0]['id'];
            data.order = filtered_chunks.length;
            data.message = msg.id;


            this.chunkService.saveChunk(data)
            .subscribe(response => {
                //this.router.navigate(['/message-detail/' + msg.id + '/' + response.id]);
                this.alertService.success('Translation requested');
                this.messageDetail.ngOnInit();
            },
            error => {
                this.alertService.error('alerts.translation-get.error');
            });

        });
    }

    toggleNotificationPanel() {

        this.notificationsListOpen = !this.notificationsListOpen;

    }

    toggleMessagesPanel() {
        if (this.messagesPanel.nativeElement.classList.contains('open')) {
            this.messagesPanel.nativeElement.classList.remove('open');
        }
        else {
            this.messagesPanel.nativeElement.classList.add('open');
        }
    }

    backToList() {
        console.log('prova');
    }
}
