import { ErrorHandler, Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { Router } from "@angular/router";
import { catchError } from "rxjs/internal/operators";

import { AuthService } from '../services/auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService, private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('access_token')) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'LP ' + localStorage.getItem('access_token')
                }
            });
        }

        return next.handle(req).pipe(catchError((error, caught) => {
            //Intercetto l'errore e lo gestisco
            this.handleError(error);

            return of(error);
        }) as any);
    }

    private handleError(err: Error | HttpErrorResponse): Observable<any> {
        
        // @PB: Handle errors better                
        if (err instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
                console.log("LP offline");
            }
            else {
                switch (err.status) {
                    case 401:
                        console.log('handled error ' + err.status);

                        this.router.navigate([`/login`]);
                        return of(err.message);
                        break;

                    /* case 404:
                        console.log('handled error ' + err.status);

                        return of(err.message);
                        break; */

                    default:
                        break;
                }
            }
        }
        else {
            console.log("other error");
        }

        throw err;
    }

}
