import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { ModalDialogModule } from 'ngx-modal-dialog';

import { NgSelectModule } from '@ng-select/ng-select';

import { polyfill as keyboardEventKeyPolyfill } from 'keyboardevent-key-polyfill';

import { AuthGuard } from './auth.guard';

import { AlertComponent } from './alert/alert.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChunkListComponent } from './chunk-list/chunk-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { MessagechunksComponent } from './messagechunks/messagechunks.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ModalComponent } from './modal/modal.component';
import { NotificationListComponent } from './notification/notification-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { RequestInterceptor } from './http-interceptors/request-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { ChunkService } from './services/chunk.service';
import { MessageService } from './services/message.service';
import { ModalService } from './services/modal.service';
import { NotificationService } from './services/notification.service';
import { RecipientService } from './services/recipient.service';
//import { SharedDataService } from './services/shared-data.service';
import { UserService } from './services/user.service';
import { UserComponent } from './user/user.component';


keyboardEventKeyPolyfill();

export function getToken() {
    return localStorage.getItem('access_token');
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    LoginComponent,
    MessageDetailComponent,
    MessageListComponent,
    ModalComponent,
    NotificationListComponent,
    PageNotFoundComponent,
    MessagechunksComponent,
    ChunkListComponent,
    UserComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
        config: {
            tokenGetter: getToken,
            whitelistedDomains: ['localhost:4000'],
            blacklistedRoutes: ['localhost:4000/api/auth']
        }
    }),
    ModalDialogModule.forRoot(),
    NgSelectModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    //NotifierModule.withConfig(customNotifierOptions),
    //TextInputAutocompleteModule
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
      AlertService,
      AuthService,
      ChunkService,
      MessageService,
      ModalService,
      NotificationService,
      RecipientService,
      //SharedDataService,
      UserService,
      AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
