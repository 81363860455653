export const JobPosition = [
    {
        'code' : '00',
        'name' : 'CEO'
    }
]

export const ProductMarket = [
    {
        'code' : '00',
        'name' : 'Clothing'
    },
    {
        'code' : '01',
        'name' : 'Food & Beverage'
    },
    {
        'code' : '02',
        'name' : 'Furnishing'
    },
    {
        'code' : '03',
        'name' : 'Biomedical'
    },
    {
        'code' : '04',
        'name' : 'Ceramic Tiles'
    },
    {
        'code' : '05',
        'name' : 'Chemical-Pharmaceutical Industry'
    },
    {
        'code' : '06',
        'name' : 'Construction Industry'
    },
    {
        'code' : '07',
        'name' : 'Design, Publishing, Paper Industry'
    },
    {
        'code' : '08',
        'name' : 'Engineering'
    },
    {
        'code' : '09',
        'name' : 'Plastic Sector'
    },
    {
        'code' : '10',
        'name' : 'Healthcare'
    },
    {
        'code' : '11',
        'name' : 'Information Technology'
    },
    {
        'code' : '12',
        'name' : 'Consulting'
    },
    {
        'code' : '13',
        'name' : 'Transports, Logistics, Packaging'
    },
    {
        'code' : '14',
        'name' : 'Other'
    }
];

export class User {
    id: number;
    username: string;
    first_name: string;
    gender: string;
    job_position: any;
    last_name: string;
    product_market: any;
    token: string;
    ui_language: string;
    user_language: string;
    country: string;
}
