import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { DataService } from '../services/data.service';
import { UserService } from '../services/user.service';

import { User, JobPosition, ProductMarket } from '../models/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
    @Input() user: User;
    @Output() userEdited = new EventEmitter<User>();

    keys = Object.values;
    languages = [];
    countries = [];
    genders = [];
    jobPosition = JobPosition;
    productMarket = ProductMarket;
    
    constructor( private dataService: DataService ) {}

    ngOnInit() {

      this.dataService.getLanguages().subscribe(
        results => {
          this.languages = results;
        }
      );
  
      this.dataService.getCountries().subscribe(
        results => {
          this.countries = results;
        }
      );

      this.dataService.getGenders().subscribe(
        results => {
          this.genders = results;
        }
      );
    }

    userEdit(user) {
        this.userEdited.emit(this.user);
    }


}
