export class Recipient {
    id: number;
    email: string;
    gender: string;
    language: string;
    country: string;
    name: string;
    surname: string;
    style: string;
}
