import { Recipient } from '../models/recipient';
import { AppConstants } from '../constants';

export class RecipientSerializer {
    API_ENDPOINT = 'recipients';

    fromJson(json: any): Recipient {
        const recipient = new Recipient();

        recipient.id = json.id;
        recipient.email = json.email;
        recipient.name = json.name;
        recipient.surname = json.surname;
        recipient.language = json.language;
        recipient.country = json.country;
        recipient.gender = json.gender;
        recipient.style = json.style;

        return recipient;
    }

    toJson(recipient: Recipient): any {
        return {
            id: recipient.id,
            email: recipient.email,
            name: recipient.name,
            surname: recipient.surname,
            language: recipient.language,
            country: recipient.country,
            gender: recipient.gender,
            style: recipient.style
        };
    }

    getUrl(recipient: Recipient): string {
        return `${AppConstants.API_URL}/${this.API_ENDPOINT}/${recipient.id}/`;
    }
}
