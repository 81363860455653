import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { map } from "rxjs/operators";

import { Recipient } from '../models/recipient';

import { MessageService } from './message.service';

import { RecipientSerializer } from '../serializers/recipient-serializer';
import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class RecipientService {
    
    API_ENDPOINT = 'recipients';

    private serializer = new RecipientSerializer();
    private selectedRecipient: Subject<Recipient> = new Subject<Recipient>();

    constructor(private http: HttpClient) { }

    getRecipients(): Observable<Recipient[]> {
        return this.http.get<Recipient[]>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/`)
            .pipe(map(data => data.map((item) => this.serializer.fromJson(item))));
    }

    newRecipient(recipient: Recipient): Observable<Recipient> {
        return this.http.post<Recipient>(
                `${AppConstants.API_URL}/${this.API_ENDPOINT}/`,
                this.serializer.toJson(recipient)
            )
            .pipe(map(data => this.serializer.fromJson(data)));
    }

    //Da fare refactoring...
    createRecipient(): Recipient {
        return this.serializer.fromJson(new Recipient());
    }

    getRecipient(id: number): Observable<Recipient> {
        return this.http.get<Recipient>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/`)
            .pipe(map(data => this.serializer.fromJson(data)));
    }

    getRawRecipient(id: number): any {
        return this.http.get<Recipient>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/`);
    }

    saveRecipient(recipient: Recipient): Observable<Recipient> {
        return this.http.put<Recipient>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${recipient.id}/`, recipient)
            .pipe(map(data => this.serializer.fromJson(data)));
    }

    deleteRecipient(id: number): Observable<Recipient> {
        return this.http.delete<Recipient>(`${AppConstants.API_URL}/${this.API_ENDPOINT}/${id}/`);
    }
}
